
import { defineComponent, computed } from "vue";
import { useStore } from "../store";

export const RegulationTextComponent = defineComponent({
  setup() {
    const store = useStore();

    const appType = computed(() => store.state.runtimeConfig.type);
    const company = computed(() => {
      switch (appType.value) {
        case "GENERAL": {
          return {
            name: "Doc4You Sp. z o. o.",
            date: "19.03.2025",
          };
        }
        case "EUROP_ASSISTANCE": {
          return {
            name: "Europ Assistance Polska Sp. z o. o.",
            date: "19.03.2025",
          };
        }
        case "MONDIAL": {
          return {
            name: "AWP Polska Sp. z o.o.",
            date: "19.03.2025",
          };
        }
        case "PZU": {
          return {
            name: "PZU ZDROWIE",
            date: "19.03.2025 r.",
          };
        }
        case "INTER": {
          return {
            name: "Doc4You Sp. z o. o.",
            date: "19.03.2025 r.",
          };
        }
        case "ALLIANZ": {
          return {
            name: "Allianz",
            date: "19.03.2025 r.",
          };
        }
        case "SALTUS": {
          return {
            name: "Saltus",
            date: "19.03.2025 r.",
          };
        }
        case "TU_ZDROWIE": {
          return {
            name: "Tu Zdrowie",
            date: "19.03.2025 r.",
          };
        }
        case "LUXMED": {
          return {
            name: "LUX MED Sp. z o.o.",
            date: "19.03.2025 r.",
          };
        }
        case "COMPENSA": {
          return {
            name: "Compensa",
            date: "19.03.2025 r.",
          };
        }
        case "INTERRISK": {
          return {
            name: "InterRisk Towarzystwo Ubezpieczeń S.A. Vienna Insurance Group",
            date: "19.03.2025 r.",
          };
        }
        case "MULTIAGENCJA": {
          return {
            name: "Multiagencja",
            date: "19.03.2025 r.",
          };
        }
        case "ALWIS": {
          return {
            name: "Alwis Ubezpieczenia",
            date: "19.03.2025 r.",
          };
        }
        case "CTU": {
          return {
            name: "Centrum Twoich Ubezpieczeń",
            date: "19.03.2025 r.",
          };
        }
        case "PHINANCE": {
          return {
            name: "Phinance S.A.",
            date: "19.03.2025 r.",
          };
        }
        case "POLISA_EXPERT": {
          return {
            name: "Polisa Expert S.A.",
            date: "19.03.2025 r.",
          };
        }
        case "UBEZPIECZENIA_AZ": {
          return {
            name: "Ubezpieczenia od A do Z Sp. z o. o. ",
            date: "19.03.2025 r.",
          };
        }
        case "UNILINK": {
          return {
            name: "Unilink S.A.",
            date: "19.03.2025 r.",
          };
        }
        case "ZDROWYBIZNES": {
          return {
            name: "zdrowybiznes",
            date: "19.03.2025 r.",
          };
        }
        case "RANKOMAT": {
          return {
            name: "Rankomat.pl sp. z o.o.",
            date: "19.03.2025 r.",
          };
        }
        case "UBEZPIECZENIALOWE": {
          return {
            name: "ubezpieczenialowe.pl Waldemar Poberejko",
            date: "19.03.2025 r.",
          };
        }
        case "IWONASZLAPAK": {
          return {
            name: "iwonaszlapak.pl Pośrednictwo Ubezpieczeniowe Iwona Szłapak",
            date: "19.03.2025 r.",
          };
        }
        case "ALWISFINANSE": {
          return {
            name: "alwisfinanse.pl Alwis Finanse",
            date: "19.03.2025 r.",
          };
        }
      }
      return {
        name: "Doc4You Sp. z o. o.",
        date: "...",
      };
    });
    return {
      appType,
      company,
    };
  },
});

export default RegulationTextComponent;
